<template>
  <div class="web_main">
    <showHeader/>
    <router-view/>
    <Footer :bgColor="bgColor" :spanColor="spanColor" :ficon="ficon"/>
  </div>
</template>
<script setup>
import {computed,onMounted,ref} from 'vue'

import cHeader from '../components/cHeader'
import dHeader from '../components/dHeader'
import Footer from '../components/cFooter.vue'
import { useRoute } from 'vue-router'

// 定义底部背景色变量
const bgColor = ref('transparent');
// 底部字体颜色
const spanColor = ref('#ccc');
const ficon = ref(true);

 const route = useRoute()
const showHeader=computed(()=>{
  if(window.innerWidth<=750){
    if(route.path=='/download'){
      bgColor.value='#B0DB9F'
      spanColor.value="#fff"
      ficon.value=false
    }else if(route.path=='/about'){
      bgColor.value='#EEECE8'
      spanColor.value="#ccc"
      ficon.value=true
    }else{
      bgColor.value='#F8F6F2'
      spanColor.value="#ccc"
      ficon.value=true
    }
  }else{
    ficon.value=true
    spanColor.value="#8b8b8b"
    if(route.path=='/about'){
      bgColor.value='#EEECE8'
    }else if(route.path=='/home'){
      bgColor.value='#2B2B2B';
    }
    else{
      bgColor.value='transparent'
    }
  }

  const meta = route.meta || {};
  if(meta.show=='B'){
    return dHeader;
  }else{
    return cHeader;
  }
})
</script>
<style scoped>
  .web_main{width: 100%;height: 100%;}
</style>